import React from 'react';
import './styles.scss';

export default function loader() {
  return (
    <div className="loader-mini loader3">
      <div>
        <div />
      </div>
    </div>
  );
}
