import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import UserDetails from '../../components/userDetails';
import Loader from '../../components/loader';
import './styles.scss';
import { getOrdersUser } from '../../services/order';
import btnDetails from '../../assets/img/info.svg';

export default function UserDetail() {
  const location = useLocation();
  const { user } = location.state;
  const [ordersUser, setOrdersUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getOrdersUser(user.id)
      .then((data) => {
        setOrdersUser(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);

  let userDetail = (
    <div className="box-client-details">
      <UserDetails user={user} />
    </div>
  );
  const filterSearchSelect = (
    <>
      <div className="filters-container">
        <div className="scrolleable">
          <div className="filterContainer">
            <div className="inputFilter">
              <h2>Search</h2>
              <input type="number" placeholder="Filter by ID" className="remove-border-right" />
            </div>
            <div className="inputFilter">
              <h2>Filter</h2>
              <select placeholder="Filter by State">
                <option value="" className="optionColor">Select an state</option>
                <option value="APPROVED" className="optionColor">Approved</option>
                <option value="REJECTED" className="optionColor">Rejected</option>
                <option value="PENDING" className="optionColor">Pending</option>
                <option value="SENT" className="optionColor">Sent</option>
                <option value="IN_PRINT" className="optionColor">In Print</option>
                <option value="READY_TO_SEND" className="optionColor">Ready no send</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const setSpanishStatus = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'Approved';
      case 'REJECTED':
        return 'Rejected';
      case 'PENDING':
        return 'Pending';
      case 'IN_PRINT':
        return 'In Print';
      case 'READY_TO_SEND':
        return 'Ready no send';
      case 'SENT':
        return 'Sent';
      default:
        return '';
    }
  };

  const setClass = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'approvedClass';
      case 'REJECTED':
        return 'rejectedClass';
      case 'PENDING':
        return 'pendingClass';
      case 'IN_PRINT':
        return 'printClass';
      case 'READY_TO_SEND':
        return 'sendClass';
      case 'SENT':
        return 'sentClass';
      default:
        return '';
    }
  };

  function renderTableOrders() {
    return ordersUser.map((order) => {
      const classOrder = setClass(order.status);
      return (
        <tr key={order.id}>
          <td>{order.id}</td>
          <td>{`$ ${order.priceTotal}`}</td>
          <td>{moment(order.createdAt).format('DD/MM/YYYY')}</td>
          <td><span className={`status ${classOrder}`}>{setSpanishStatus(order.status)}</span></td>
          <td>
            <Link to={{
              pathname: '/order-detail',
              state: {
                order,
              },
            }}
            >
              <img alt="details" src={btnDetails} />
            </Link>
          </td>
        </tr>
      );
    });
  }

  let body = (
    <>
      <div className="table-container">
        <h2 className="subtitle">Order summary</h2>
        <div className="scrolleable">
          <div className="filterContainer">
            <div className="inputFilter">
              <h2>Search by ID</h2>
              <input type="number" placeholder="Filter by ID" className="remove-border-right" />
            </div>
            <div className="inputFilter">
              <h2>Search by state</h2>
              <select placeholder="Filter by State">
                <option value="" className="optionColor">Select a state</option>
                <option value="APPROVED" className="optionColor">Approved</option>
                <option value="REJECTED" className="optionColor">Rejected</option>
                <option value="PENDING" className="optionColor">Pending</option>
                <option value="SENT" className="optionColor">Sent</option>
                <option value="IN_PRINT" className="optionColor">In Print</option>
                <option value="READY_TO_SEND" className="optionColor">Ready to send</option>
              </select>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Price</th>
                <th>Date</th>
                <th>State</th>
                <th className="small">Details</th>
              </tr>
            </thead>
            <tbody>
              {renderTableOrders()}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
    userDetail = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Ocurrió un error</h3>
      </>
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">Client Profile</h2>
        {filterSearchSelect}
        {userDetail}
        {body}
      </div>
    </div>
  );
}
