import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './styles.scss';
import moment from 'moment';
import Loader from '../../components/loader';
import btnDetails from '../../assets/img/info.svg';
import { getUsers } from '../../services/users';
import { useAuth } from '../../hooks/auth';

export default function Users() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem('token') || null;
  const { unsetToken } = useAuth();
  const history = useHistory();

  function logout() {
    unsetToken();
    history.push('/login');
  }

  useEffect(() => {
    if (!token) {
      return logout();
    }
    setLoading(true);
    return getUsers()
      .then((data) => {
        setUsers(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);

  function renderTable() {
    return users.map((user) => {
      const validEmail = user.validEmail ? 'Validated' : 'Unvalidated';
      return (
        <tr key={user.id}>
          <td>{`${user.firstName} ${user.surName}`}</td>
          <td>{user.email}</td>
          <td>{moment(user.createdAt).format('DD/MM/YYYY')}</td>
          <td>{validEmail}</td>
          {/* <td>{`${'3'}`}</td> */}
          <td>
            <Link to={{
              pathname: '/user-detail',
              state: {
                user,
              },
            }}
            >
              <img alt="details" src={btnDetails} />
            </Link>
          </td>
        </tr>
      );
    });
  }

  const table = <tbody>{renderTable()}</tbody>;

  let bodyCategory = (
    <>
      <div className="table-container">
        <div className="scrolleable">
          <div className="filterContainer">
            <div className="inputFilter">
              <h2>Search by ID</h2>
              <input type="number" placeholder="Filter by ID" className="remove-border-right" />
            </div>
            <div className="inputFilter">
              <h2>Search by state</h2>
              <select placeholder="Filtrar por Estado">
                <option value="" className="optionColor">Select a state</option>
                <option value="APPROVED" className="optionColor">Approved</option>
                <option value="REJECTED" className="optionColor">Rejected</option>
                <option value="PENDING" className="optionColor">Pending</option>
                <option value="SENT" className="optionColor">Sent</option>
                <option value="IN_PRINT" className="optionColor">In Print</option>
                <option value="READY_TO_SEND" className="optionColor">Ready to send</option>
              </select>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Created At</th>
                <th className="small">State</th>
                {/* <th className="small">Órdenes</th> */}
                <th className="small">Details</th>
              </tr>
            </thead>
            {table}
          </table>
        </div>
      </div>
    </>
  );

  if (loading) {
    bodyCategory = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    bodyCategory = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">Clients</h2>
        {bodyCategory}
      </div>
    </div>
  );
}
