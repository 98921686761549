import React, { useEffect, useState } from 'react';
import Loader from '../../components/loader';
import { getOrders } from '../../services/order';
import './styles.scss';

export default function Home() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getOrders()
      .then((data) => {
        setOrders(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [localStorage.getItem('token')]);

  function renderTable() {
    return orders.map((order) => {
      return (
        <tr key={order.id}>
          <td>{order.id}</td>
          <td>{`${order.priceTotal}`}</td>
          <td className="td-with-input">
            $
            {' '}
            <input placeholder="Nuevo monto" type="text" />
            <button type="submit" className="btn-gral">SAVE</button>
          </td>
        </tr>
      );
    });
  }
  const table = <tbody>{renderTable()}</tbody>;
  const filterSearchSelect = (
    <>
      <div className="filters-container">
        <div className="scrolleable">
          <div className="filterContainer">
            <div className="inputFilter">
              <h2>Search</h2>
              <input type="number" placeholder="Search" />
            </div>
            <div className="inputFilter">
              <h2>Filter</h2>
              <select placeholder="Filter by state">
                <option value="" className="optionColor">All</option>
                <option value="APPROVED" className="optionColor">Approved</option>
                <option value="REJECTED" className="optionColor">Rejected</option>
                <option value="PENDING" className="optionColor">Pending</option>
                <option value="SENT" className="optionColor">Sent</option>
                <option value="IN_PRINT" className="optionColor">In print</option>
                <option value="READY_TO_SEND" className="optionColor">Ready to send</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  let body = (
    <>
      <div className="user-details back-content">
        <h3 className="subtitle">Pricing update</h3>
        <div className="box-form form-delimitated">
          <div className="box-personal-info inputFilter">
            {filterSearchSelect}
          </div>
        </div>
        <div className="table-container no-margin">
          <div className="scrolleable center-table-items">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Total Price</th>
                  <th>Actual Price</th>
                </tr>
              </thead>
              {table}
            </table>
          </div>
        </div>
      </div>
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">Configuration</h2>
        {body}
      </div>
    </div>
  );
}
