import React, { useState } from 'react';
import { changeStateOrder } from '../../services/order';
import LoaderMini from '../loaderMini';
import './styles.scss';

const states = [{
  name: 'Pending',
  value: 'PENDING',
}, {
  name: 'Approved by admin',
  value: 'APPROVED_BY_ADMIN',
}, {
  name: 'Approved',
  value: 'APPROVED',
}, {
  name: 'Rejected',
  value: 'REJECTED',
}, {
  name: 'In print',
  value: 'IN_PRINT',
}, {
  name: 'Ready to send',
  value: 'READY_TO_SEND',
}, {
  name: 'Sent',
  value: 'SENT',
}];

export default function dropboxOrderStatus({ orderData }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [order, setOrder] = useState(orderData);

  const handleChangeDropbox = (event) => {
    event.stopPropagation();
    setLoading(true);
    const hashId = `${order.hash}$-${order.id}`;
    const newState = event.target.value;
    changeStateOrder(hashId, newState)
      .then(() => {
        setOrder({ ...order, status: newState });
      })
      .catch(() => {
        setError(true);
        setInterval(() => {
          setError(false);
        }, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const options = states.map((state) => {
    return <option value={state.value} key={state.name}>{state.name}</option>;
  });

  const select = loading ? <LoaderMini /> : <select value={order.status} className="remove-border-right" id="stateChange" onChange={handleChangeDropbox}>{options}</select>;

  return (
    <div className="box-form">
      <label htmlFor="stateChange">
        Change Actual State
        {select}
      </label>
      {error && <p className="error-status">Couldn&apos;t update state</p>}
    </div>
  );
}
