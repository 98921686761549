import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AuthProvider from './hooks/auth';
import PrivateRoute from './private-route';
import Home from './pages/home';
import Login from './pages/login';
import Header from './components/header';
import Footer from './components/footer';
import UserRegistration from './pages/user-registration';
import LoadMovement from './pages/load-movement';
import Detail from './pages/detail';
import Users from './pages/users';
import Configuration from './pages/configuration';
import UserDetail from './pages/user-detail';
import EditProducts from './pages/edit-products';
import AddItemLibrary from './pages/add-item-library';
import ListProducts from './pages/list-products';
import ListContacts from './pages/list-contacts';
import Coupon from './pages/create-coupon';
import CouponList from './pages/list-coupons';
import ListCategories from './pages/list-categories';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <>
            <Header />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/user-registration" component={UserRegistration} />
            <PrivateRoute exact path="/load-movement" component={LoadMovement} />
            <PrivateRoute exact path="/order-detail" component={Detail} />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/user-detail" component={UserDetail} />
            <PrivateRoute exact path="/addItemLibrary" component={AddItemLibrary} />
            <PrivateRoute exact path="/configuration" component={Configuration} />
            <PrivateRoute exact path="/edit-products" component={EditProducts} />
            <PrivateRoute exact path="/list-products" component={ListProducts} />
            <PrivateRoute exact path="/coupon-create" component={Coupon} />
            <PrivateRoute exact path="/coupons" component={CouponList} />
            <PrivateRoute exact path="/list-contacts" component={ListContacts} />
            <PrivateRoute exact path="/list-categories" component={ListCategories} />
            <Footer />
          </>
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
