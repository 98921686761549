import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import logo from '../../assets/img/brand-w.png';
import { login as loginService } from '../../services/authentication';
import Loader from '../../components/loader';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const history = useHistory();

  function renderMsgError() {
    if (error) {
      return (
        <p className="error-txt">{`${errorMessage}.`}</p>
      );
    }
    return null;
  }

  function errorStyle() {
    if (error) {
      return ('error');
    }
    return ('');
  }
  function login() {
    setLoading(true);
    loginService(email, password)
      .then((data) => {
        localStorage.setItem('token', data.token);
        setLoading(false);
        setEmail('');
        setPassword('');
        return history.push('/');
      })
      .catch((resp) => {
        const err = resp.response;
        setError(true);
        if (err.data.code === 'missing_params') {
          setErrorMessage('Missing fields');
        }
        if (err.data.code === 'authentication_failed') {
          setErrorMessage('Email or password are wrong');
        }
      });
  }
  return (
    <div className="login-page">
      {!loading ? (
        <div className="login-side">
          <div className={`login-box ${errorStyle()}`}>
            <div className="brand">
              <img alt="" src={logo} />
            </div>
            <div className="line-form">
              <span>E-mail</span>
              <input
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="line-form">
              <span>Password</span>
              <input
                type="password"
                autoComplete="new-password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            {renderMsgError()}
            <button type="button" className="btn-gral" onClick={login}>Login</button>
          </div>
        </div>
      ) : (
        <div className="login-side">
          <Loader />
        </div>
      )}
      <div className="lateral" />
    </div>
  );
}
