import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Loader from '../../components/loader';
import Pagination from '../../components/pagination';
import { getContacts } from '../../services/contacts';
import './styles.scss';

export default function ListContacts() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(20);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getContacts()
      .then((data) => {
        setContacts(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [localStorage.getItem('token')]);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = contacts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function renderTable() {
    return currentPosts.map((contactData) => {
      return (
        <tr key={contactData.id}>
          <td>{moment(contactData.createdAt).format('DD/MM/YYYY')}</td>
          <td>{contactData.name}</td>
          <td>{contactData.email}</td>
          <td>
            {`${contactData.codePhone} - ${contactData.phone}`}
          </td>
          <td>
            <p data-tip data-for={`tooltip${contactData.id}`}>
              View content
            </p>
            <ReactTooltip id={`tooltip${contactData.id}`}>
              <h4>{contactData.content}</h4>
            </ReactTooltip>
          </td>
        </tr>
      );
    });
  }

  const table = <tbody>{renderTable()}</tbody>;
  const emptyContacts = (!contacts || contacts.length === 0) && <h3 className="empty-orders">No messages were found</h3>;

  let body = (
    <>
      <div className="user-details back-content">
        <h3 className="subtitle">Contacts</h3>
        <div className="table-container no-margin">
          <div className="scrolleable center-table-items">
            <table>
              <thead>
                <tr>
                  <th>Created At</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Content</th>
                </tr>
              </thead>
              {table}
            </table>
            {emptyContacts}
          </div>
        </div>
        <div>
          <Pagination
            postsPerPage={postPerPage}
            totalPosts={contacts.length}
            paginate={paginate}
            current={1}
          />
        </div>
      </div>
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">Contact List</h2>
        {body}
      </div>
    </div>
  );
}
