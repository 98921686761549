import * as axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
};

export const downloadList = (productId) => {
  return window.open(`${API_URL}api/download-file/${productId}`, '_blank');
};

export const fileUpload = (file) => {
  return axios.post(`${API_URL}api/upload-file`, file, getHeaders())
    .then((resp) => resp.data);
};

export const uploadManyFiles = (files, orderId) => {
  return axios.post(`${API_URL}api/orders/${orderId}/files`, files, getHeaders())
    .then((resp) => resp.data);
};

export const getOrderFiles = (orderId) => {
  return axios.get(`${API_URL}api/orders/${orderId}/files`)
    .then((resp) => resp.data);
};

export const editFileStatus = (fileId, value) => {
  return axios.post(`${API_URL}api/files/${fileId}/edit`, { value }, getHeaders())
    .then((resp) => resp.data);
};

export default {
  downloadList,
  fileUpload,
  uploadManyFiles,
  getOrderFiles,
  editFileStatus,
};
