import * as axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
};

export const getOrders = () => axios.get(`${API_URL}api/orders`, getHeaders())
  .then((resp) => resp.data);

export const getOrderByHash = (hashId) => axios.get(`${API_URL}api/order/${hashId}?order=true`)
  .then((resp) => resp.data);

export const getOrderItemsByHash = (hashId) => axios.get(`${API_URL}api/order/${hashId}?order=false`)
  .then((resp) => resp.data);

export const changeStateOrder = (hashId, status) => axios.post(`${API_URL}api/order/change-status/${hashId}`, { status }, getHeaders())
  .then((resp) => resp.data);

export const getOrdersUser = (userId) => axios.get(`${API_URL}api/orders?user=${userId}`, getHeaders())
  .then((resp) => resp.data);

export default {
  getOrders,
  getOrderByHash,
  getOrderItemsByHash,
  getOrdersUser,
};
