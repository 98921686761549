import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import Loader from '../../components/loader';
import Pagination from '../../components/pagination';
import { getAllCategories, disableCategory } from '../../services/product';
import './styles.scss';

export default function ListProducts() {
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(20);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getAllCategories()
      .then((data) => {
        setCategories(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [localStorage.getItem('token')]);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentCategories = categories.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function formatBoolean(data) {
    if (data) {
      return <p className="status rejectedClass">Disabled</p>;
    }
    return <p className="status sentClass">Enabled</p>;
  }

  function changeCategoryStatus(categoryId) {
    setLoading(true);
    setError(false);
    disableCategory(categoryId)
      .then(() => {
        getAllCategories()
          .then((data) => {
            setCategories([]);
            setCategories(data);
            setLoading(false);
          });
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }

  function formatButtonStatus(category) {
    if (category.isDisabled) {
      return <button type="button" className="btn-gral" onClick={() => changeCategoryStatus(category.id)}>Enable</button>;
    }
    return <button type="button" className="btn-gral" onClick={() => changeCategoryStatus(category.id)}>Disable</button>;
  }
  function renderTable() {
    return currentCategories.map((category) => {
      return (
        <tr key={category.id}>
          <td>{category.id}</td>
          <td>{category.name}</td>
          <td>{moment(category.createdAt).format('DD/MM/YYYY')}</td>
          <td>{formatBoolean(category.isDisabled)}</td>
          <td>{formatButtonStatus(category)}</td>
        </tr>
      );
    });
  }

  const cattt = () => {
    return (
      <select placeholder="Filter by state">
        <option value="" className="optionColor">All</option>
        {categories.map((category) => (
          <option value={category.id} className="optionColor">{category.name}</option>
        ))}
      </select>
    );
  };

  const table = <tbody>{renderTable()}</tbody>;
  const cat = <div>{cattt()}</div>;
  const emptyCategories = (!categories || categories.length === 0) && <h3 className="empty-orders">No categories were found</h3>;
  const filterSearchSelect = (
    <>
      <div className="filters-container">
        <div className="scrolleable">
          <div className="filterContainer">
            <div className="inputFilter">
              <h2>Filter</h2>
              {cat}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  let body = (
    <>
      <div className="user-details back-content">
        <h3 className="subtitle">Categories</h3>
        <div className="box-form form-delimitated">
          <div className="box-personal-info inputFilter">
            {filterSearchSelect}
          </div>
        </div>
        <div className="table-container no-margin">
          <div className="scrolleable center-table-items">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>State</th>
                  <th>Change Status</th>
                </tr>
              </thead>
              {table}
            </table>
            {emptyCategories}
          </div>
        </div>
        <div>
          <Pagination
            postsPerPage={postPerPage}
            totalPosts={categories.length}
            paginate={paginate}
            current={1}
          />
        </div>
      </div>
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">Category List</h2>
        {body}
      </div>
    </div>
  );
}
