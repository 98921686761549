import React from 'react';
import './styles.scss';

export default function DetailBox({ clave, valor }) {
  return (
    <div className="box-detail back-content">
      <span>{clave}</span>
      {valor}
    </div>
  );
}
