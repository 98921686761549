import React, { useRef, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import trash from '../../assets/img/trash.svg';
import cross from '../../assets/img/cross.svg';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import { fileUpload } from '../../services/file';
import Loader from '../../components/loader';
import { createNewProduct, getCategories } from '../../services/product';

export default function Home() {
  const [state, setState] = useState({ selectedFile: [] });
  const [charged, setCharged] = useState(false);
  const [tags, setTags] = useState([]);
  const [nameFile, setNameFile] = useState('');
  const [fileId, setFileId] = useState(null);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [price, setPrice] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCharged(false);
    getCategories()
      .then((data) => {
        setCategories(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);
  const inputRef = useRef();

  function handleChange(value) {
    if (!tags.some((elem) => elem === value)) {
      setTags([...tags, value]);
    }
  }

  function previewImage() {
    const file = document.getElementById('file').files;
    if (file.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        document.getElementById('preview')?.setAttribute('src', event.target.result);
      };
      fileReader.readAsDataURL(file[0]);
    }
  }

  function handleChangeImage(e) {
    const formData = new FormData();
    formData.append('file', e);
    fileUpload(formData)
      .then(({ id }) => {
        setState({ selectedFile: [e] });
        setFileId(id);
        previewImage();
        setCharged(true);
      });
  }

  function handleRemoveImage(name) {
    state.selectedFile.forEach((index) => {
      if (state.selectedFile[0].name === name) {
        state.selectedFile.splice(index);
        setCharged(false);
      }
    });
  }

  function handleRemove(id) {
    const newTags = tags.filter((elem) => elem !== id);
    setTags(newTags);
  }
  let files = (
    <>
      <div className="box-address-info">
        <span className="subtitle">Image</span>
        <div className="box">
          <h4>Choose an image product</h4>
          <button
            type="submit"
            className="btn-gral"
            onClick={() => {
              inputRef.current.click();
            }}
          >
            SEARCH PHOTO

          </button>
          <input
            type="file"
            id="file"
            ref={inputRef}
            accept="image/*"
            hidden
            onChange={(e) => {
              handleChangeImage(e.target.files[0]);
              previewImage();
            }}
          />
        </div>
      </div>
    </>
  );

  if (charged === true) {
    files = (
      <>
        <div className="box-address-info">
          <span className="subtitle">Image</span>
          <div className="box-file-charged">
            <div className="img-section">
              <img id="preview" alt="" />
            </div>
            <div className="selectedFile-container">
              <div className="selectedFile-section">
                <p>{state.selectedFile[0].name}</p>
                <h4>{`${state.selectedFile[0].size}Mb`}</h4>
              </div>
              <div className="selectedFile-img">
                <img src={trash} alt="preview" />
                <button
                  type="submit"
                  className="delete-red none"
                  onClick={() => {
                    handleRemoveImage(state.selectedFile[0].name);
                  }}
                >
                  DELETE
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const createProduct = () => {
    if (!nameFile || !price || !fileId) {
      toast.error('Something went wrong while creating a product!');
      setError(true);
      return;
    }
    const productToCreate = {
      name: nameFile,
      tags,
      price: price * 100,
      fileId,
      width,
      height,
    };
    setLoading(true);
    createNewProduct(productToCreate)
      .then(() => {
        setLoading(false);
        toast.success('Product created successfully!');
        setPrice('');
        setNameFile('');
        setTags([]);
        handleRemoveImage(state.selectedFile[0].name);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.taget.reset();
  };

  let data = (
    <>
      <div className="user-details back-content">
        <h3 className="subtitle">Information</h3>
        <div className="box-form form-delimitated">
          <div className="box-personal-info inputFilter">
            <div>
              <span className="subtitle">Name </span>
              <input type="text" placeholder="Insert Name" value={nameFile} onChange={(e) => setNameFile(e.target.value)} />
            </div>
            <div>
              <span className="subtitle">Price (by 2x2 unit) </span>
              <input type="number" placeholder="Insert a price" value={price} onChange={(e) => setPrice(e.target.value)} />
            </div>
            <div>
              <span className="subtitle">Category </span>
              <select value={tags} onChange={(e) => handleChange(e.target.value)}>
                <option>Select a category</option>
                {
                  categories.map((elem) => {
                    return (
                      <option key={elem.name} value={elem.name}>{elem.name}</option>
                    );
                  })
                }
              </select>
              <div>
                <h3 className="subtitle">Size</h3>
                <span className="subtitle">Width</span>
                <input type="number" placeholder="Insert width" value={width} onChange={(e) => setWidth(e.target.value)} />
              </div>
              <div>
                <span className="subtitle">Height</span>
                <input type="number" placeholder="Insert height" value={height} onChange={(e) => setHeight(e.target.value)} />
              </div>
              <div className="list-component">
                {tags.map((item) => {
                  return (
                    <div className="list-divisor">
                      <p>
                        {item}
                        <button type="button" onClick={() => handleRemove(item)}>
                          {' '}
                          <img src={cross} alt="preview" />
                        </button>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

          </div>
          {files}
        </div>
        <div className="buttonsItemLibrary">
          <button type="submit" className="btn-mini-cancel">CANCEL</button>
          <button
            type="submit"
            className="btn-mini-save"
            onClick={createProduct}
            onSubmit={handleSubmit}
          >
            SAVE
          </button>
        </div>
        {error ? <h2>Error</h2> : null}
      </div>
    </>
  );

  if (loading) {
    data = (
      <Loader />
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <ToastContainer />
        <h2 className="title-page">Add product</h2>
        {data}
      </div>
    </div>
  );
}
