import React from 'react';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/img/brand-w.png';

export default function Nav({ activeMenu }) {
  return (
    <div className={`nav-component ${activeMenu}`}>
      <div className="logo">
        <NavLink to="/">
          <img alt="" src={logo} />
        </NavLink>
      </div>
      <ul>
        <li>
          <NavLink to="/" exact activeClassName="active">Orders</NavLink>
        </li>
        <li>
          <NavLink to="/users" exact activeClassName="active">Clients</NavLink>
        </li>
        <li>
          <NavLink to="/addItemLibrary" exact activeClassName="active">Add product</NavLink>
        </li>
        <li>
          <NavLink to="/list-products" exact activeClassName="active">List products</NavLink>
        </li>
        <li>
          <NavLink to="/list-categories" exact activeClassName="active">List Categories</NavLink>
        </li>
        <li>
          <NavLink to="/list-contacts" exact activeClassName="active">List Contacts</NavLink>
        </li>
        <li>
          <NavLink to="/configuration" exact activeClassName="active">Settings</NavLink>
        </li>
        <li>
          <NavLink to="/coupons" exact activeClassName="active">List Coupons</NavLink>
        </li>
        {/* <li>
          <NavLink to="/user-registration" exact activeClassName="active">Alta de usuario</NavLink>
        </li> */}
      </ul>
    </div>
  );
}
