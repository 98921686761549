import React from 'react';
import './styles.scss';

export default function TableResume({ items }) {
  console.log(items);

  const formatPrice = (price) => {
    if (!price) return '';
    return parseFloat(price).toFixed(2);
  };

  const formatSize = (size) => {
    if (!size) return '';
    return `${size.width}x${size.height}`;
  };

  const formatCategory = (category) => {
    if (!category) return '';
    return category.name;
  };

  const body = (
    <>
      <h3 className="subtitle">Details</h3>
      <div className="box-form form-delimitated">
        <label htmlFor="filterType">
          Filter by type
          <select className="remove-border-right" id="filterType">
            <option>All</option>
            <option>Stickers</option>
            <option>Labels</option>
          </select>
        </label>
      </div>
      <div className="scrolleable">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 ? (
              items.map(({ item, category }) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{formatCategory(category)}</td>
                    <td>{item.quantity}</td>
                    <td>{`$${formatPrice(item.price)}`}</td>
                    <td>{formatSize(item.size)}</td>
                  </tr>
                );
              })
            ) : <></>}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <div className="table-container table-detail">
      {body}
    </div>
  );
}
