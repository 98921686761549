import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { getOrders } from '../../services/order';
import './styles.scss';
// import btnDelete from '../../assets/img/delete.svg';
// import btnEdit from '../../assets/img/edit.svg';
import btnDetails from '../../assets/img/info.svg';
import Loader from '../../components/loader';

export default function Home() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [userTables, setUserTables] = useState([]);
  const [selectId, setSelectId] = useState('');
  const [userStatus, setUserStatus] = useState([]);
  const [selectStatus, setSelectStatus] = useState('');

  const setSpanishStatus = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'Approved';
      case 'APPROVED_BY_ADMIN':
        return 'Approved By Admin';
      case 'REJECTED':
        return 'Rejected';
      case 'PENDING':
        return 'Pending';
      case 'IN_PRINT':
        return 'In Print';
      case 'READY_TO_SEND':
        return 'Ready to send';
      case 'SENT':
        return 'Sent';
      default:
        return '';
    }
  };

  const setClass = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'approvedClass';
      case 'APPROVED_BY_ADMIN':
        return 'approvedByAdminClass';
      case 'REJECTED':
        return 'rejectedClass';
      case 'PENDING':
        return 'pendingClass';
      case 'IN_PRINT':
        return 'printClass';
      case 'READY_TO_SEND':
        return 'sendClass';
      case 'SENT':
        return 'sentClass';
      default:
        return '';
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(false);
    getOrders()
      .then((data) => {
        setOrders(data);
        setUserTables(data);
        setUserStatus(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [localStorage.getItem('token')]);

  const filter = (termSearch) => {
    const searchResult = userTables.filter((element) => {
      if (element.id.toString().includes(termSearch.toString())) {
        return element;
      }
      return null;
    });
    setOrders(searchResult);
  };

  const filterStatus = (termSearch) => {
    const searchResult = userStatus.filter((element) => {
      if (element.status.toLowerCase().toString().includes(termSearch.toString())
       || element.status.toUpperCase().toString().includes(termSearch.toString())) {
        return element;
      }
      return null;
    });
    setOrders(searchResult);
  };

  const handleChange = (e) => {
    setSelectId(e.target.value);
    filter(e.target.value);
  };

  const handleChangeStatus = (e) => {
    setSelectStatus(e.target.value);
    filterStatus(e.target.value);
  };

  function renderTable() {
    return orders.map((order) => {
      const classOrder = setClass(order.status);
      return (
        <tr key={order.id}>
          <td>{order.id}</td>
          <td>
            {moment(order.createdAt).format('DD/MM/YYYY')}
          </td>
          <td>{order.email}</td>
          <td>{`${order.priceTotal}`}</td>

          <td><span className={`status ${classOrder}`}>{setSpanishStatus(order.status)}</span></td>
          {/* <td><img alt="" src={btnEdit} /></td>
            <td><img alt="" src={btnDelete} /></td> */}
          <td>
            <Link to={{
              pathname: '/order-detail',
              state: {
                order,
              },
            }}
            >
              <img alt="details" src={btnDetails} />
            </Link>
          </td>
        </tr>
      );
    });
  }

  const table = <tbody>{renderTable()}</tbody>;
  const emptyOrders = (!orders || orders.length === 0) && <h3 className="empty-orders">No orders were found</h3>;
  let bodyCategory = (
    <>
      <div className="table-container">
        <div className="scrolleable">
          <div className="filterContainer">
            <div className="inputFilter">
              <h2>Search by ID</h2>
              <input type="number" value={selectId} onChange={handleChange} placeholder="Filter by ID" className="remove-border-right" />
            </div>
            <div className="inputFilter">
              <h2>Search by state</h2>
              <select value={selectStatus} onChange={handleChangeStatus} placeholder="Filter by state">
                <option value="" className="optionColor">Select a state</option>
                <option value="APPROVED" className="optionColor">Approved</option>
                <option value="REJECTED" className="optionColor">Rejected</option>
                <option value="PENDING" className="optionColor">Pending</option>
                <option value="SENT" className="optionColor">Sent</option>
                <option value="IN_PRINT" className="optionColor">In Print</option>
                <option value="READY_TO_SEND" className="optionColor">Ready to send</option>
              </select>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Created At</th>
                <th className="small">Email</th>
                <th>Price</th>
                <th>State</th>
                {/* <th className="small">Editar</th>
                <th className="small">Borrar</th> */}
                <th className="small">Details</th>
              </tr>
            </thead>
            {table}
          </table>
          {emptyOrders}
        </div>
      </div>
    </>
  );

  if (loading) {
    bodyCategory = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    bodyCategory = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">Orders</h2>
        {bodyCategory}
      </div>
    </div>
  );
}
