import React from 'react';
import './styles.scss';

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul>
        {pageNumbers.map((number) => (
          <>
            <li key={number} className="paginator">
              <button onClick={() => paginate(number)} type="button">
                {number}
              </button>
            </li>
          </>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
