import axios from 'axios';

const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
};

export const createCoupon = (couponToCreate) => axios.post(`${process.env.REACT_APP_API_URL}api/coupon/create`, couponToCreate, getHeaders())
  .then((resp) => resp.data);

export const getCoupons = () => axios.get(`${process.env.REACT_APP_API_URL}api/coupons`, getHeaders())
  .then((resp) => resp.data);

export default {
  createCoupon,
  getCoupons,
};
