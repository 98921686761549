/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import './styles.scss';
import Nav from '../nav';
import signOut from '../../assets/img/sign_out.svg';
import menuBtn from '../../assets/img/menu.svg';

export default function Header() {
  const [menuMobile, setMenuMobile] = useState('');
  const { unsetToken } = useAuth();
  const history = useHistory();

  function logout() {
    unsetToken();
    history.push('/login');
  }

  return (
    <div className="header-component">
      <button type="button" className="mobile-btn" onClick={() => setMenuMobile('active')}>
        <img alt="" src={menuBtn} />
      </button>
      <button type="button" className={`mobile-background ${menuMobile}`} onClick={() => setMenuMobile(' ')}> </button>
      User Name
      <button type="button" className="signout-btn" onClick={() => logout()}>
        <img alt="" src={signOut} />
      </button>
      <Nav activeMenu={menuMobile} />
    </div>
  );
}
