import * as axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
};
export const getItemLibraryProductsById = (idItem) => axios.get(`${API_URL}api/libraryproducts?id=${idItem}`, getHeaders())
  .then((resp) => resp.data);
export const editProduct = (id, dataToUpload) => axios.post(`${API_URL}api/product/${id}/edit`, dataToUpload, getHeaders())
  .then((resp) => resp.data);
export const getCategories = () => axios.get(`${API_URL}api/librarycategories`, getHeaders())
  .then((resp) => resp.data);

export const getAllCategories = () => axios.get(`${API_URL}api/categories`, getHeaders())
  .then((resp) => resp.data);

export const disableCategory = (idCat) => axios.delete(`${API_URL}api/disable-category/${idCat}`, getHeaders())
  .then((resp) => resp.data);
export const getItemLibraryProducts = () => axios.get(`${API_URL}api/libraryproducts`, getHeaders())
  .then((resp) => resp.data);

export const createNewProduct = (product) => axios.post(`${API_URL}api/product/create`, product, getHeaders())
  .then((resp) => resp.data);

export default {
  createNewProduct,
  getItemLibraryProducts,
  editProduct,
  getCategories,
  getAllCategories,
  disableCategory,
  getItemLibraryProductsById,
};
