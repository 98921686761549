import React from 'react';
import './styles.scss';

export default function LoadMovement() {
  const name = 'Lorem Ipsum';
  return (
    <div className="user-registration">
      <div className="content-gral">
        <h2 className="title-page">
          Add movement to
          {` ${name}`}
        </h2>
        <div className="content-form">
          <div className="line-form">
            <span>Amount</span>
            <input type="email" placeholder="Insert Amount" />
          </div>
          <div className="line-form">
            <span>Last Name</span>
            <select name="" id="">
              <option selected disabled>Choose an option</option>
              <option>option 1</option>
              <option>option 2</option>
              <option>option 3</option>
            </select>
          </div>
          <div className="line-form">
            <span>Description</span>
            <textarea />
          </div>
          <button type="button" className="btn-gral">Confirm</button>
        </div>
      </div>
    </div>
  );
}
