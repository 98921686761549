import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { createCoupon } from '../../services/coupon';
import Loader from '../../components/loader';
import './styles.scss';

export default function CreateCoupon() {
  const [code, setCode] = useState('');
  const [expiration, setExpiration] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [type, setType] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const validateCoupon = () => {
    setLoading(true);
    if (!code || !percentage || !type) {
      setError(true);
      setLoading(false);
      return;
    }
    const newCoupon = {
      percentage: percentage / 100,
      code,
      type: type.toUpperCase(),
      expireAt: expiration ?? '',
    };
    createCoupon(newCoupon)
      .then(() => {
        setLoading(false);
        toast.success('Coupon has been created successfully');
        setCode('');
        setExpiration('');
        setPercentage(0);
        setType(null);
      })
      .catch(() => setError(true));
  };

  let data = (
    <>
      { error && <span>Error</span>}
      <div className="user-details back-content">
        <ToastContainer />
        <h3 className="subtitle">Create coupon</h3>
        <div className="box-form form-delimitated">
          <div className="box-create-coupon inputFilter">
            <div>
              <span className="subtitle">Code</span>
              <input type="text" placeholder="Insert code" value={code} onChange={(e) => setCode(e.target.value)} />
            </div>
            <div>
              <span className="subtitle">Expire at (if left empty, the coupon expires in 15 days)</span>
              <input type="date" placeholder="Insert an expiration date" value={expiration} onChange={(e) => setExpiration(e.target.value)} />
            </div>
            <div>
              <span className="subtitle">Percentage</span>
              <input type="number" placeholder="Insert a percentage" value={percentage} onChange={(e) => setPercentage(e.target.value)} />
            </div>
            <div>
              <span className="subtitle">Type </span>
              <select
                value={type || ''}
                onChange={(e) => setType(e.target.value)}
              >
                <option>
                  Select a category
                </option>
                <option value="unique">UNIQUE</option>
                <option value="general">GENERAL</option>
              </select>
            </div>
          </div>
        </div>
        <div className="buttonsItemLibrary">
          <button type="submit" className="btn-mini-cancel">CANCEL</button>
          <button
            type="submit"
            className="btn-mini-save"
            onClick={validateCoupon}
          >
            SAVE
          </button>
        </div>
      </div>
    </>
  );

  if (loading) {
    data = (
      <Loader />
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">Create coupon</h2>
        {data}
      </div>
    </div>
  );
}
