import * as axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
};

export const signupUser = (email, firstName, lastName) => {
  return axios
    .post(`${API_URL}api/auth/signup`, {
      email,
      firstName,
      lastName,
    }).then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};

export const getUsers = () => {
  return axios
    .get(`${API_URL}api/users`, getHeaders())
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};
