import React, { useState, useEffect } from 'react';
import './styles.scss';
import { ToastContainer, toast } from 'react-toastify';
import FileDetail from '../fileDetail';
import 'react-toastify/dist/ReactToastify.css';
import { getOrderFiles, uploadManyFiles } from '../../services/file';

export default function Files({ items, orderId }) {
  const [filesAdmin, setFilesAdmin] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getOrderFiles(orderId)
      .then((data) => {
        setFilesAdmin(filesAdmin.concat(data));
        setLoading(false);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  const handleChange = (files) => {
    const formData = new FormData();
    const filesToUpload = Object.values(files);
    filesToUpload.forEach((file) => formData.append('files', file));
    uploadManyFiles(formData, orderId)
      .then((data) => {
        setFilesAdmin(filesAdmin.concat(data));
        toast.success('File has been uploaded successfully');
      })
      .catch(() => {
        toast.error('There was an error trying to upload a file');
      });
  };

  return (
    <div className="files-content back-content">
      <ToastContainer />
      {loading ?? console.log(loading)}
      {error ?? console.log(error)}
      <h3 className="subtitle">Files</h3>
      <div className="box-files-content">
        {items.map((item) => (
          <FileDetail item={item.item} file={item.fileData} key={item.item.id} />))}
      </div>
      <h3 className="subtitle">Corrected Files</h3>
      <div className="file-btn">
        <input
          type="file"
          id="files"
          name="files"
          multiple
          onChange={(e) => {
            handleChange(e.target.files);
          }}
        />
      </div>
      <div className="box-files-content">
        {filesAdmin.length > 0
          ? filesAdmin.map((file) => {
            return <FileDetail file={file} key={file.name} />;
          }) : null}
      </div>
    </div>
  );
}
