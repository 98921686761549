import React, { useState } from 'react';
import './styles.scss';
import HyperModal from 'react-hyper-modal';
import Eye from '../../assets/img/eye.svg';
import Download from '../../assets/img/download.svg';
import { downloadList, editFileStatus } from '../../services/file';

function formatFileType(mime) {
  const types = {
    'image/jpeg': 'JPG',
    'image/png': 'PNG',
    'image/svg+xml': 'SVG',
    'image/webp': 'WEBP',
  };
  const typeDefault = mime || ' - ';
  return types[mime] || typeDefault;
}

export default function FileDetail({ item, file }) {
  const isFileAdmin = file && file.last != null;
  const [checked, setChecked] = useState(isFileAdmin ? file.last : null);

  const editLastStatus = (e) => {
    editFileStatus(file.id, e.target.checked)
      .then(() => {
        setChecked(!checked);
      });
  };

  const downloadFileByItem = () => {
    return downloadList(item.productId);
  };

  let fileBtn = <div />;
  if (file) {
    fileBtn = (
      <button type="button" className="btn-file-download" onClick={downloadFileByItem}>
        <img src={Download} alt="download" />
      </button>
    );
  }

  const fileMimeType = formatFileType(file && file.mime);
  return (
    <div className="file-detail">
      <div className="extension">{fileMimeType}</div>
      <div className="txt-file">
        <h4>{file ? file.name : ' - '}</h4>
        { item ? <p>{`Item ID: ${item.id}`}</p> : null}
      </div>
      {item ? fileBtn : (
        <div className="checkbox-eyeIcon-container">
          <div className="tooltip">
            <input type="checkbox" checked={checked} onChange={editLastStatus} />
            <span className="tooltiptext">Show this image to the user</span>
          </div>
          <HyperModal
            renderOpenButton={(requestOpen) => {
              return (
                <button onClick={requestOpen} type="button" className="preview-image-download-button">
                  <img src={Eye} alt="preview" />
                </button>
              );
            }}
          >
            <div className="modal-image-cover">
              <img
                className="file-admin"
                src={`${process.env.REACT_APP_API_URL}${file.path}`}
                alt=""
              />
            </div>
          </HyperModal>
        </div>
      ) }
    </div>
  );
}
