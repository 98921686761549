import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './hooks/auth';

export default function PrivateRoute({ component: Component, ...restOfProps }) {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        if (isLoggedIn()) {
          return <Component {...props} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
}
