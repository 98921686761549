import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import dateFormat from 'dateformat';
import DetailBox from '../../components/detailBox';
import Files from '../../components/files';
import TableResume from '../../components/tableResume';
import UserDetails from '../../components/userDetails';
import BackButton from '../../assets/img/arrow-left.svg';
import './styles.scss';
import { getOrderByHash } from '../../services/order';
import Loader from '../../components/loader';
import DropboxOrderStatus from '../../components/dropboxOrderStatus';

export default function Detail() {
  const location = useLocation();
  const { order } = location.state;
  const element = 'Order';
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderInformation, setOrderInformation] = useState(null);

  const setSpanishStatus = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'Approved';
      case 'REJECTED':
        return 'Rejected';
      case 'PENDING':
        return 'Pending';
      case 'IN_PRINT':
        return 'In Print';
      case 'READY_TO_SEND':
        return 'Ready to Send';
      case 'SENT':
        return 'Sent';
      default:
        return '';
    }
  };

  const setClass = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'approvedClass';
      case 'REJECTED':
        return 'rejectedClass';
      case 'PENDING':
        return 'pendingClass';
      case 'IN_PRINT':
        return 'printClass';
      case 'READY_TO_SEND':
        return 'sendClass';
      case 'SENT':
        return 'sentClass';
      default:
        return '';
    }
  };

  useEffect(() => {
    const hashId = `${order.hash}$-${order.id}`;
    setLoading(true);
    getOrderByHash(hashId)
      .then((actualOrder) => {
        setOrderInformation(actualOrder.order);
        setItems(actualOrder.orderItems);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  let body = (
    <>
      <div className="files-content-cols">
        <div className="left-col-files">
          <div>
            <TableResume items={items} />
          </div>
        </div>
        <div className="right-col-files">
          <Files items={items} orderId={order.id} />
        </div>
      </div>
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Something went wrong getting order data</h3>
      </>
    );
  }

  return (
    <div className="user-registration">
      <div className="content-gral">
        <Link to="/" className="back-btn">
          <img alt="back" src={BackButton} />
        </Link>
        <div className="top-detail">
          <h2 className="title-page">
            {` ${element}`}
            {`#${order.id}`}
            <span className={`status ${setClass(order.status)}`}>{setSpanishStatus(order.status)}</span>
          </h2>
          <DropboxOrderStatus orderData={order} />
        </div>
        <div className="content-detail">
          <DetailBox clave="Creation Date" valor={`${dateFormat(order.createdAt, 'dd/mm/yyyy - HH:MM')}hs`} />
          <DetailBox clave="Total Amount" valor={items.length} />
          <DetailBox clave="Total Price" valor={`$${parseFloat(order.priceTotal).toFixed(2)}`} />
        </div>
        <div className="box-client-details">
          <UserDetails order={orderInformation} />
        </div>
        {body}
      </div>
    </div>
  );
}
