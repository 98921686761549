import React, { useState } from 'react';
import './styles.scss';
import copyIcon from '../../assets/img/copy.svg';

export default function UserRegistration() {
  const [copiedClass, setCopiedClass] = useState('');
  const [userCreatedClass, setUserCreatedClass] = useState('');

  function copyPass() {
    setCopiedClass('copied-successful');
    const content = document.getElementById('password');
    content.select();
    document.execCommand('copy');
  }

  return (
    <div className="user-registration">
      <div className="content-gral">
        <h2 className="title-page">User Creation</h2>
        <div className={`content-form ${userCreatedClass}`}>
          <form>
            <div className="line-form">
              <span>Name</span>
              <input type="text" placeholder="Insert Name" />
            </div>
            <div className="line-form">
              <span>Last Name</span>
              <input type="text" placeholder="Insert Last Name" />
            </div>
            <div className="line-form">
              <span>E-mail</span>
              <input type="email" placeholder="nombre@ejemplo.com" />
            </div>
            <button type="button" className="btn-gral" onClick={() => setUserCreatedClass('user-created-successful')}>Confirm</button>
          </form>
          <div className="generated-password-box">
            <p>The generated password for this user is:</p>
            <div className="copy-box">
              <input type="text" className="password" value="dsA87JfjñReJKñdfD52" id="password" />
              <button type="button" title="Copy" onClick={() => copyPass()}><img alt="copy" src={copyIcon} /></button>
              <span className={`copied ${copiedClass}`}>Copied</span>
            </div>
            <span className="msg">Remember to copy and save this password before exiting</span>
            <a href="/" className={`btn-gral ${copiedClass}`}>Accept</a>
          </div>
        </div>
      </div>
    </div>
  );
}
