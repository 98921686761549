import React, {
  useContext,
  createContext,
} from 'react';
import decode from 'jwt-decode';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  function setToken(newToken) {
    localStorage.setItem('token', newToken);
  }

  function unsetToken() {
    localStorage.removeItem('token');
  }
  const isTokenExpired = (token) => {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  function isLoggedIn() {
    const token = localStorage.getItem('token');
    if ((token && token !== '') && !isTokenExpired(token)) {
      return true;
    }
    return false;
  }

  return (
    <AuthContext.Provider value={{
      setToken,
      unsetToken,
      isLoggedIn,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
