import * as axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = (email, password) => {
  return axios
    .post(`${API_URL}api/login`, {
      email,
      password,
    }).then((resp) => resp.data);
};

export default login;
