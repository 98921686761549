import axios from 'axios';

const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
};

export const createMessage = (newMessage, chatId) => axios.post(`${process.env.REACT_APP_API_URL}api/messages/${chatId}`, newMessage, getHeaders())
  .then((resp) => resp.data);

export default {
  createMessage,
};
