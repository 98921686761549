import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import HyperModal from 'react-hyper-modal';
import { Link } from 'react-router-dom';
import Eye from '../../assets/img/eye.svg';
import { getCoupons } from '../../services/coupon';
import Loader from '../../components/loader';
import './styles.scss';

export default function ListProducts() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCoupons()
      .then((allCoupons) => {
        setCoupons(allCoupons);
        setLoading(false);
      })
      .catch(() => setError(true));
  }, []);

  let body = (
    <>
      <div className="user-details back-content">
        <h3 className="subtitle">Coupons</h3>
        { error && <h3>Error to get coupons</h3>}
        <div className="table-container no-margin">
          { coupons
            ? (
              <div className="scrolleable center-table-items">
                <table>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>ExpireAt</th>
                      <th>Percentage</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    { coupons.map((coupon) => (
                      <tr key={coupon.id}>
                        <td>{coupon.code}</td>
                        <td>
                          <Moment format="DD-MM-YYYY - HH:mm">
                            {coupon.expireAt}
                          </Moment>
                        </td>
                        <td>
                          {coupon.percentage * 100}
                          %
                        </td>
                        <td>{coupon.types}</td>
                        <td>{coupon.status}</td>
                        <td>
                          {' '}
                          <HyperModal
                            renderOpenButton={(requestOpen) => {
                              return (
                                <button onClick={requestOpen} type="button" className="preview-image-download-button">
                                  <img src={Eye} alt="preview" />
                                </button>
                              );
                            }}
                          >
                            <div className="coupon-modal-container">
                              <h2>Coupon detail</h2>
                              <div className="coupon-modal-label-text">
                                <h4>Code</h4>
                                <p>{coupon.code}</p>
                              </div>
                              <div className="coupon-modal-label-text">
                                <h4>ExpireAt</h4>
                                <p>
                                  <Moment format="DD-MM-YYYY - HH:mm">
                                    {coupon.expireAt}
                                  </Moment>

                                </p>
                              </div>
                              <div className="coupon-modal-label-text">
                                <h4>Percentage</h4>
                                <p>
                                  {coupon.percentage * 100}
                                  %

                                </p>
                              </div>
                              <div className="coupon-modal-label-text">
                                <h4>Type</h4>
                                <p>{coupon.types}</p>
                              </div>
                              <div className="coupon-modal-label-text">
                                <h4>Status</h4>
                                <p>{coupon.status}</p>
                              </div>
                            </div>
                          </HyperModal>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : <h3>No coupons exist</h3>}
        </div>
      </div>
    </>
  );

  if (loading) {
    body = (
      <Loader />
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <div className="section-create-coupon">
          <h2 className="title-page">Coupons List</h2>
          <Link to="/coupon-create">
            <button type="button" className="btn-gral create-coupon-button">
              Create Coupon
            </button>
          </Link>
        </div>
        {body}
      </div>
    </div>
  );
}
