import * as axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
};

export const getContacts = () => axios.get(`${API_URL}api/contacts`, getHeaders())
  .then((resp) => resp.data);

export default {
  getContacts,
};
